.bg-active {
  background-color: #e8f5e1 !important;
}

a:hover {
  background-color: #f4f8f2 !important;
}

.text-active {
  color: #033300 !important;
}

a:hover .menu-text {
  color: #033300 !important;
}

a:hover .menu-svg {
  fill: #599d36;
  fill-opacity: 1;
}

.menu-item.menu-item-open > .menu-link {
  background-color: #e8f5e1 !important;
}

.menu-item.menu-item-open > .menu-link .menu-text {
  color: #033300 !important;
}

.menu-item.menu-item-open > .menu-link .menu-svg {
  fill: #255915;
  fill-opacity: 1;
}

.menu-icon svg {
  margin-left: -2px;
  height: 1.5rem !important;
  width: 1.5rem !important;
}
